import React from 'react';
const classes = {
  wrapper: 'mt-16 blog-content',
  title: 'mt-16 text-4xl text-gray-900 font-bold',
};
const SectionAbout = ({ about }) => {
  return (
    <div className={classes.wrapper}>
      <div className="mb-6">
        <p>{about}</p>
      </div>
    </div>
  );
};

export default SectionAbout;
